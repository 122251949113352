import React from "react";
import { useOutletContext } from "react-router";
import { BlogContext } from "./blog-outlet";
import { CardPost, CardPostSkeleton } from "../../components/card";

const PostsList = () => {

  const { loading, posts } = useOutletContext<BlogContext>();

  return loading || posts.length === 0
    ? Array.from({ length: 2 }).map((_, i) => <CardPostSkeleton key={i} />)
    : posts.map((p, i) => <CardPost key={i} post={p} />);

}

export default PostsList;
