import React, { FunctionComponent } from "react";
import Banner from "../../components/banner";
import Section from "../../components/wrapper/section";
import FourColumn from "../../components/wrapper/fourColumn";
import { MapContainer, MapMarker } from "../../bundles/map-implement";
import { useIntl } from "react-intl";
import ContactForm from "../../components/form/forms";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SeoHelmet from "../../components/seo-helmet";
import { commonDefs } from "../../i18n";

import "./contact.scss";
import image from "../../assets/chalets-agence-.jpg";

const HomeMarker = () => {
    const intl = useIntl();

    return (
        <div className="homeMarker">
        <span>{intl.formatMessage({id: "maytain-agency", defaultMessage: "L’agence Maytain"})}</span>
            <div>
                <FontAwesomeIcon icon={faHome} />
            </div>
        </div>
    )
};

const Contact:FunctionComponent = ()=>{
    const intl = useIntl();
    const position:number[] =[46.237680373887855,6.857830543902882];

    return (
        <>
            <SeoHelmet
                title={intl.formatMessage({ id: "seo-title-contact", defaultMessage: "Maytain | Contactez l'agence immobilière"})}
                description={intl.formatMessage({id:"seo-description-contact", defaultMessage:"Nous répondons rapidement à toutes vos questions. Écrivez-nous à l'adresse info@maytain.com ou appelez-nous au 024 477 24 21."})}
                canonical="/administration"
                image={image}
            />
            <Banner title="Contact"/>
            <Section>
                <FourColumn>
                    <div className="contact-block">
                        <div className="contact-icone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="39.208" height="31.195" viewBox="0 0 39.208 31.195">
                            <g transform="translate(1.045 0.75)">
                                <path d="M5.712,4h29.7a3.723,3.723,0,0,1,3.712,3.712V29.984A3.723,3.723,0,0,1,35.407,33.7H5.712A3.723,3.723,0,0,1,2,29.984V7.712A3.723,3.723,0,0,1,5.712,4Z" transform="translate(-2 -4)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                                <path d="M39.119,6,20.56,18.992,2,6" transform="translate(-2 -2.288)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                            </g>
                        </svg>
                        </div>
                        <h5>{intl.formatMessage({id:"email", defaultMessage: "Email"})}</h5>
                        <a href="mailto:info@maytain.com"><b>{intl.formatMessage({id:"general",  defaultMessage: "Général"})} :</b> info@maytain.com</a>
                        <a href="mailto:location@maytain.com"><b>{intl.formatMessage(commonDefs.rent)} :</b> location@maytain.com</a>
                        <a href="mailto:camille@maytain.com"><b>{intl.formatMessage(commonDefs.sale)} :</b> camille@maytain.com</a>
                        <a href="mailto:administrations@maytain.com"><b>{intl.formatMessage({id:"administration",  defaultMessage: "Admin"})} :</b> administrations@maytain.com</a>
                    </div>
                    <div className="contact-block">
                        <div className="contact-icone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40.244" height="40.321" viewBox="0 0 40.244 40.321">
                            <path d="M40.852,31.063v5.844a3.9,3.9,0,0,1-4.246,3.9,38.549,38.549,0,0,1-16.81-5.98A37.984,37.984,0,0,1,8.108,23.135,38.549,38.549,0,0,1,2.128,6.246,3.9,3.9,0,0,1,6,2h5.844a3.9,3.9,0,0,1,3.9,3.35,25.011,25.011,0,0,0,1.364,5.474,3.9,3.9,0,0,1-.877,4.11l-2.474,2.474A31.166,31.166,0,0,0,25.444,29.1l2.474-2.474a3.9,3.9,0,0,1,4.11-.877A25.011,25.011,0,0,0,37.5,27.108a3.9,3.9,0,0,1,3.35,3.954Z" transform="translate(-1.36 -1.25)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                        </svg>
                        </div>
                        <h5>{intl.formatMessage({id:"phone",  defaultMessage: "Téléphone"})}</h5>
                        <a href="tel:+4124 477 24 21"><b>{intl.formatMessage({id:"agency",  defaultMessage: "Agence"})} :</b> 024 477 24 21</a>
                        <a href="tel:+4179 351 55 18"><b>{intl.formatMessage({id:"urgency",  defaultMessage: "Urgences"})} :</b> 079 351 55 18</a>
                    </div>
                    <div className="contact-block">
                        <div className="contact-icone">
                            <svg xmlns="http://www.w3.org/2000/svg" width="37.767" height="37.767" viewBox="0 0 37.767 37.767">
                                <path d="M37.284,18.4a18.886,18.886,0,1,0,13.352,5.532A18.75,18.75,0,0,0,37.284,18.4Zm12,30.885a16.962,16.962,0,1,1,4.975-12A16.877,16.877,0,0,1,49.285,49.285Z" transform="translate(-18.4 -18.4)" fill="#fff"/>
                                <path d="M237.137,162.9h0l-5.229.008v-7.352a.954.954,0,0,0-1.907,0v8.313a.927.927,0,0,0,.294.668.981.981,0,0,0,.683.278h0l6.159-.008a.954.954,0,1,0,0-1.907Z" transform="translate(-213.183 -143.775)" fill="#fff"/>
                            </svg>
                        </div>
                        <h5>{intl.formatMessage({id:"schedule",  defaultMessage: "Horaires"})}</h5>
                        <p><b>{intl.formatMessage({id:"monday-saturday",  defaultMessage: "Lundi - Samedi"})} :</b> {intl.formatMessage({ id:"schedule1", defaultMessage: "8h00 - 18h00"})}</p>
                        <p><b>{intl.formatMessage({id:"sunday",  defaultMessage: "Dimanche"})} :</b> {intl.formatMessage({id:"schedule2",  defaultMessage: "9h00 - 12h00"})}</p>
                    </div>
                    <a className="contact-block" target="__blank" href="https://www.google.com/maps/place/Agence+Immobili%C3%A8re+Maytain+S%C3%A0rl/@46.2376377,6.8552231,17z/data=!3m1!4b1!4m6!3m5!1s0x478ea388bf572a4f:0xbfe4352c0e91ebb5!8m2!3d46.237634!4d6.857798!16s%2Fg%2F1tg14n5g?entry=ttu">
                        <div className="contact-icone">
                            <svg xmlns="http://www.w3.org/2000/svg" width="33.107" height="40.02" viewBox="0 0 33.107 40.02">
                                <g transform="translate(1 1)">
                                    <path d="M34.107,16.554c0,12.1-15.554,22.466-15.554,22.466S3,28.651,3,16.554a15.554,15.554,0,1,1,31.107,0Z" transform="translate(-3 -1)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                    <circle cx="5.924" cy="5.924" r="5.924" transform="translate(9.478 9.586)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                                </g>
                            </svg>
                        </div>
                        <h5>{intl.formatMessage({id:"address",  defaultMessage: "Adresse"})}</h5>
                        <p>Rte du village 15</p>
                        <p>1875 Morgins</p>
                        <p>{intl.formatMessage({id:"switzerland",  defaultMessage: "Suisse"})}</p>
                    </a>
                </FourColumn>
            </Section>
            <Section title={intl.formatMessage({ id: "form-title", defaultMessage: "Formulaire de contact"})}
                     subtitle={intl.formatMessage(commonDefs.contact)}
                     className="section-form">
                <ContactForm email="camille@maytain.com"/>
            </Section>
            <MapContainer center={position} zoom={17}>
                <MapMarker position={position} icon={HomeMarker()} />
            </MapContainer>
        </>
    )
};

export default Contact
