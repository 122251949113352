import React, { useRef } from "react";
import { Outlet } from "react-router";
import { gql, useQuery } from "@apollo/client";
import { Post } from "../../models/cms";
import Banner from "../../components/banner";
import "./blog.scss";

const BLOG_QUERY = gql`
  query getPost($templateId: UUID!) {
    cmsPages(templateId: $templateId) { id publishedDate updatedDate data }
  }
`;

export type BlogContext = {
  loading: boolean;
  posts: Post[];
}

const BlogOutlet = () => {

  const templateId = useRef<string>("03e8bb2b-0fa2-40e6-8d48-5b39a16b4a54");
  const { loading, data } = useQuery(BLOG_QUERY, {
    variables: { templateId: templateId.current }
  });

  return (
    <>
      <Banner title="Blog" />
      <section className="blog">
        <Outlet context={{ loading, posts: data?.cmsPages || [] }} />
      </section>
    </>);

}

export default BlogOutlet;
