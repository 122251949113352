type Field = {
  type: string;
  slug: string;
}

type TranslatableText = {
  languageCode: string;
  description: string;
}

export type CmsTextField = Omit<Field, "type"> & {
  type: "Text";
  text: TranslatableText[];
};

export type CmsHtmlField = Omit<Field, "type"> & {
  typer: "Html";
  html: TranslatableText[];
}

export type CmsImageField = Omit<Field, "type"> & {
  type: "Image";
  originalUrl: string;
  alternativeText?: TranslatableText[];
}

type AnyField = CmsTextField | CmsHtmlField | CmsImageField;

export type Post = {
  id: string,
  publishedDate: string;
  updatedDate?: string;
  data: AnyField[];
}

export const cmsField = <T extends AnyField>(data: AnyField[] = [], slug: string): T | undefined => {
  const search = data.find(field => field.slug === slug);
  return !!search ? search as T : undefined;
}

export const cmsTranslatableValue = (val: TranslatableText[] = [], lang: string) =>
  val?.find((val: TranslatableText) => val.languageCode.toLowerCase() === lang)?.description || "";
