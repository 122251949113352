import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext, useParams } from "react-router";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { useIsMounted } from "@ct-react/core";
import { useLocaleContext } from "@ct-react/locale";
import {
  cmsField,
  CmsHtmlField,
  CmsImageField,
  CmsTextField,
  cmsTranslatableValue,
  Post as PostModel
} from "../../models/cms";
import { BlogContext } from "./blog-outlet";
import "./blog.scss";

const Post = () => {

  const intl = useIntl();
  const isMounted = useIsMounted();
  const { postId } = useParams<{ postId: string }>();
  const { locale: { basename: lang } } = useLocaleContext();
  const ctx = useOutletContext<BlogContext>();

  const [ post, setPost ] = useState<PostModel | undefined>(ctx.posts.find(p => p.id === postId));
  const title = useMemo(() => cmsField<CmsTextField>(post?.data || [], "blog-title")!, [ post, lang ]);
  const image = useMemo(() => cmsField<CmsImageField>(post?.data || [], "blog-lead-image"), [ post, lang ])
  const lead = useMemo(() => cmsField<CmsTextField>(post?.data || [], "blog-lead"), [ post, lang ]);
  const content = useMemo(() => cmsField<CmsHtmlField>(post?.data || [], "blog-content")!, [ post, lang ]);

  useEffect(() => {
    if (!isMounted) return;
    setPost(ctx.posts?.find(p => p.id === postId));
  }, [ postId, ctx ]);

  if (ctx.loading || !post)
    return (
      <div className="post">
        <Skeleton className="post-img" inline={true} />
        <h1><Skeleton width="75%" /></h1>
        <p className="post-lead">
          <Skeleton count={3} />
          <Skeleton width="35%" />
        </p>
        <div className="post-body">
          <h2><Skeleton width="25%" inline={true} /></h2>
          <p>
            <Skeleton count={5} />
            <Skeleton width="70%" />
          </p>
        </div>
      </div>);

  return (
    <article className="post">
      {!!image &&
        <div className="post-img">
          <img src={image.originalUrl} alt={cmsTranslatableValue(image.alternativeText || [], lang)} />
        </div>
      }
      <div className="post-label">
        <FontAwesomeIcon icon={faCalendar} />
        {intl.formatDate(new Date(post.publishedDate), { year: "numeric", month: "long", day: "numeric" })}
      </div>
      <h1>{cmsTranslatableValue(title.text, lang)}</h1>
      {!!lead &&
        <p className="post-lead">{cmsTranslatableValue(lead.text, lang)}</p>
      }
      <div className="post-body format-html"
           dangerouslySetInnerHTML={{ __html: cmsTranslatableValue(content.html, lang)}} />
    </article>);

};

export default Post;
